<template>
  <LiefengContent>
    <template v-slot:title
      >员工关联管理</template
    >
    <template v-slot:toolsbarRight>
      <Button
        style="margin: 0 5px 0 13px"
        type="primary"
        icon="md-add"
        @click="addContact"
      >
        新增关联</Button
      >
      <Button
        @click="exportExcel"
        type="error"
        icon="ios-cloud-download-outline"
        >导出</Button
      >
      <Button type="success" @click="$router.push('/sponsorindex')"
        >返回</Button
      >
    </template>
    <template v-slot:contentArea>
      <LiefengTable
        :talbeColumns="talbeColumns"
        :tableData="tableData"
        :total="total"
        :curPage="pageNumber"
        @hadlePageSize="getList"
        :loading="loading"
        :fixTable="true"
        :pageSize="pageSize"
        :pagesizeOpts="[20, 30, 50, 100]"
      ></LiefengTable>
      <!-- 新增赞助商 -->
      <LiefengModal title="关联员工" :value="userModal" @input="addSponsorFn">
        <template v-slot:contentarea>
          <Form
            ref="contactUser"
            :model="contactUser"
            :rules="userValidate"
            :label-width="80"
          >
            <FormItem label="姓名" prop="username">
              <Input
              :maxlength="20"
                v-model.trim="contactUser.username"
                placeholder="请输入姓名"
              ></Input>
            </FormItem>
            <FormItem label="手机号" prop="mobile">
              <Input
              :maxlength="20"
                v-model.trim="contactUser.mobile"
                placeholder="请输入手机号"
              ></Input>
            </FormItem>
          </Form>
        </template>
        <template v-slot:toolsbar>
          <Button type="info" @click="addSponsorFn(false)">取消</Button>
          <Button
            style="margin-left: 10px"
            type="primary"
            @click="saveContactUser('contactUser')"
            >保存</Button
          >
        </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
//@route("/sponsorcontactuser")
import { validateMobile, validateEmail } from "@/utils/validate";
import LiefengContent from "@/components/LiefengContent";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
    loading: false,
      total: 0,
      pageNumber: 1,
      pageSize: 20,
      tableData: [],
      talbeColumns: [
        {
          title: "名称",
          key: "userName",
          minWidth: 80,
          align: "center",
        },
        {
          title: "手机号",
          key: "mobile",
          minWidth: 80,
          align: "center",
        },
        {
          title: "登记时间",
          key: "gmCreate",
          minWidth: 120,
          align: "center",
        },
        // { //不展示  更新 取消 也暂时不实现
        //     title: "状态",
        //     key: "name",
        //     minWidth: 80,
        //     align: "center",
        // },
        {
          title: "操作",
          slot: "action",
          align: "center",
          width: 120,

          render: (h, params) => {
            return h("div", [
              h(
                "Dropdown",
                {
                  props: {
                    transfer: true,
                    // trigger: "click",
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        type: "info",
                        size: "small",
                        icon: "ios-arrow-down"
                      },
                    },
                    "编辑"
                  ),
                  h(
                    "DropdownMenu",
                    {
                      slot: "list",
                    },
                    [
                      // h(
                      //     "DropdownItem", {
                      //         nativeOn: {
                      //             click: () => {
                      //                 this.cancal(params);
                      //             },
                      //         },
                      //         style: {
                      //             textAlign: "center",
                      //         },
                      //     },
                      //     "取消关联"
                      // ),
                      // h(
                      //     "DropdownItem", {
                      //         nativeOn: {
                      //             click: () => {
                      //                 this.updateMobile(params);
                      //             },
                      //         },
                      //         style: {
                      //             textAlign: "center",
                      //         },
                      //     },
                      //     "更新手机号"
                      // ),
                      h(
                        "DropdownItem",
                        {
                          nativeOn: {
                            click: () => {
                              this.remove(params);
                            },
                          },
                          style: {
                            textAlign: "center",
                          },
                        },
                        "删除"
                      ),
                    ]
                  ),
                ]
              ),
            ]);
          },
        },
      ],
      userModal: false,
      urlParams: {},
      contactUser: {
        username: "",
        mobile: "",
      },
      userValidate: {
        username: [
          {
            required: true,
            message: "名称不能为空",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            validator: validateMobile,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
     //导出excel
    exportExcel() {
      this.$Modal.confirm({
        title: "温馨提示",
        content:
          "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
        onOk: () => {
          let tHeader = [
            "名称",
            "手机号",
            "登记时间"
          ];
          let filterVal = [
            "userName",
            "mobile",
            "gmCreate"
          ];
          this.$core.exportExcel(
            tHeader,
            filterVal,
            this.tableData,
            "员工关联列表"
          );
        },
      });
    },
      addSponsorFn(status) {
          this.userModal = status;
      },
    getList(data) {
      this.loading = true;
      let params = {
        orgCode: this.urlParams.row.orgCode,
        page: this.pageNumber,
        pageSize: this.pageSize,
      };
      if(data) {
          params.page = data.page;
          params.pageSize = data.pageSize;
      }

      this.$get("/old/api/pc/componentPrize/staffPage", params)
        .then((res) => {
          if (res.code === "200") {
            res.dataList.map(item => {
              if(item.gmCreate) {
                item.gmCreate = this.$core.formatDate(new Date(item.gmCreate),"yyyy-MM-dd hh:mm:ss")
              }
            })
            this.tableData = res.dataList;
            this.loading = false;
          }else {
              this.$Message.error({
                  background: true,
                  content: "获取数据失败"
              })
              this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addContact() {
      this.userModal = true;
    },
    saveContactUser(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          let params = {
            ...this.contactUser,
            oem: parent.vue.oemInfo.oemCode,
            orgCode: this.urlParams.row.orgCode,
          };
          this.$post("/old/api/pc/componentPrize/addStaff", params)
            .then((res) => {
              if (res.code === "200") {
                this.$Message.success("操作成功");
                this.userModal = false;
                this.contactUser = {};
                this.getList();
              }else {
                  this.$Message.error({
                      background: true,
                      content: "操作失败"
                  })
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    cancal() {},
    updateMobile() {},
    remove(data) {
      if (data) {
        let params = {
          custGlobalId: data.row.custGlobalId,
          orgCode: this.urlParams.row.orgCode,
        };
        this.$post("/old/api/pc/componentPrize/deleteStaff", params).then(
          (res) => {
            if (res.code === "200") {
              this.$Message.success("操作成功");
              this.getList();
            }else {
                this.$Message.error({
                    background: true,
                    content: '操作失败'
                })
            }
          }
        );
      }
    },
    setSelection() {},
  },
  created() {
    if (this.$route.query.data) {
      let Base64 = require("js-base64").Base64;

      let temp = Base64.decode(this.$route.query.data);
      this.urlParams = JSON.parse(temp);
    }
    this.getList();
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
</style>